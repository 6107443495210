const ISO_DATETIME_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,9})?Z$/;

export function cloneObject<T>(objectToClone: T): T {
    return JSON.parse(JSON.stringify(objectToClone), (_, value) => {
        if (typeof value === "string" && value.match(ISO_DATETIME_REGEX)) {
            return new Date(value);
        }
        return value;
    });
}
