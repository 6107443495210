import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[(!_vm.availableNumbers.length)?_c(VOverlay,{staticClass:"text-center",attrs:{"value":true}},[_c('p',[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"32","width":"2"}})],1)]):_c(VMain,[_c('div',{staticClass:"text-right"},[_c(VIcon,{staticClass:"ma-2",on:{"click":_vm.close}},[_vm._v("mdi-close")])],1),_c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VListItemGroup,[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.availableNumbers),function(n){return _c(VListItem,{key:n.number,on:{"click":function($event){return _vm.openChat(n.number)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-whatsapp")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(n.label))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.formatNumber(n.number)))])],1)],1)}),1)],1),_c('p',{staticClass:"float-right mr-5"},[_c('small',[_vm._v("powered by "),_c('strong',[_c('a',{attrs:{"target":"_blank","href":"https://dealerdesk.com"}},[_vm._v("dealerdesk")])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }