export default {
    common: {
        dataProtectionBefore: "I have read the",
        dataProtection: "data protection declaration",
        dataProtectionAfter: "",
        noDataProtection: "Confirm that you have read the data protection declaration.",
    },
    videochat: {
        availableEmployees: "Available employees",
        noEmployees: "There are currently no employees available for a video chat.",
        name: "Your Name",
        noName: "Enter your name.",
        start: "Start video chat",
        title: "Questions?",
        explanation: "We are here to help.",
        buttonText: "Start videochat now",
    },
    whatsapp: {
        defaultText: "Hello! I would like more information about the following offer:",
        title: "WhatsApp Chat",
        buttonText: "Send message now",
    },
};
