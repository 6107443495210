import { createIFrame, mountComponent, openModal, showButton } from "./loader";
import VideochatWidget from "./videochat/VideochatWidget.vue";
import WhatsAppWidget from "./whatsapp/WhatsAppWidget.vue";

export const _videochat = mountComponent(VideochatWidget);
const videochat = createIFrame("_videochat");
export const videochatModal = openModal(videochat, { modalWidth: 600, autoHeight: true });
export const videochatButton = showButton(() => import("./videochat/VideochatButton.vue"), [videochatModal]);

export const _whatsApp = mountComponent(WhatsAppWidget);
const whatsApp = createIFrame("_whatsApp");
export const whatsAppModal = openModal(whatsApp, { modalWidth: 400, autoHeight: true });
export const whatsAppButton = showButton(() => import("./whatsapp/WhatsAppButton.vue"), [whatsAppModal]);
