import de from "./i18n/de";
import en from "./i18n/en";
import axios from "axios";
import Vue from "vue";
import VueI18n, { LocaleMessageObject } from "vue-i18n";
import Vuetify from "vuetify/lib";
import vuetifyDe from "vuetify/src/locale/de";
import { ThemeOptions } from "vuetify/types/services/theme";

import "@fontsource/roboto/100.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/900-italic.css";
import "@mdi/font/css/materialdesignicons.css";

interface WidgetOptions {
    readonly params?: object;
    readonly lang?: string;
    readonly labels?: { [lang: string]: LocaleMessageObject };
    readonly theme?: ThemeOptions;
}

interface WidgetIFrameOptions extends WidgetOptions {
    readonly width?: number | string;
    readonly height?: number | string;
    readonly elementId?: string;
}

interface ModalOptions {
    readonly modalWidth?: number | string;
    readonly modalHeight?: number | string;
    readonly persistent?: boolean;
    readonly autoHeight?: boolean;
}

type WidgetModalOptions = WidgetIFrameOptions & ModalOptions;

interface WidgetButtonOptions extends WidgetModalOptions {
    readonly position?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
    readonly offsetX?: number | string;
    readonly offsetY?: number | string;
    readonly buttonParams?: object;
}

const scriptUrl = (document.currentScript as HTMLScriptElement).src;
const cssBaseUrl = scriptUrl
    .split("/")
    .slice(0, -1)
    .join("/");
const baseUrl = scriptUrl
    .split("/", 4)
    .slice(0, -1)
    .join("/");

axios.defaults.baseURL = baseUrl;

function toSize(size: number | string | undefined, fallback: string) {
    return typeof size === "string" ? size : size ? `${size}px` : fallback;
}

function randomId() {
    return (
        "dd-" +
        Math.random()
            .toString(36)
            .substring(2, 13)
    );
}

export function createIFrame(component: string) {
    return (options: WidgetIFrameOptions = {}) => {
        const iframe = document.createElement("iframe");
        iframe.width = toSize(options.width, "100%");
        iframe.height = toSize(options.height, "100%");
        iframe.style.border = "0";
        iframe.src = "javascript:void(0);";
        iframe.srcdoc = `<!DOCTYPE html>
<html style="overflow-y: auto;">
    <head>
        <link rel="stylesheet" href="${cssBaseUrl}/widgets.css" />
    </head>
    <body>
        <script src="${scriptUrl}"></script>
        <script>Dealerdesk.${component}(${JSON.stringify({
            params: options.params,
            lang: options.lang,
            labels: options.labels,
            theme: options.theme,
        })});</script>
    </body>
</html>`;

        if (options.elementId) {
            document.getElementById(options.elementId)?.appendChild(iframe);
        }
        return iframe;
    };
}

export function mountComponent(component: any) {
    return (options: WidgetOptions) => {
        const lang = (options.lang || navigator.language).startsWith("de") ? "de" : "en";

        Vue.use(VueI18n);
        const i18n = new VueI18n({
            messages: { de, en },
            locale: lang,
        });

        Vue.use(Vuetify);
        const vuetify = new Vuetify({
            lang: {
                locales: { de: vuetifyDe },
                current: lang,
            },
            theme: options.theme || {},
        });

        const app = new Vue({
            i18n,
            vuetify,
            render: (h) => h(component, { props: options.params }),
        });
        const div = document.createElement("div");
        document.body.appendChild(div);
        app.$mount(div);
        i18n.mergeLocaleMessage("de", options.labels?.de || {});
        i18n.mergeLocaleMessage("en", options.labels?.en || {});
    };
}

export function openModal(
    iframeFactory: (options?: WidgetIFrameOptions) => HTMLIFrameElement,
    defaultModalOptions: ModalOptions = {}
) {
    return (options: WidgetModalOptions = {}) => {
        options = { ...defaultModalOptions, ...options };
        const iframe = iframeFactory(options);

        const id = randomId();
        const style = document.createElement("style");
        style.textContent = `
#${id} {
    position: fixed;
    z-index: 2147483647;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
#${id} > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${toSize(options.modalWidth, "95%")};
    max-width: 95%;
    height: ${options.autoHeight ? "0px" : toSize(options.modalHeight, "95%")};
    max-height: ${toSize(options.modalHeight, "95%")};
    overflow: hidden;
}`;
        document.body.appendChild(style);

        const outerDiv = document.createElement("div");
        outerDiv.id = id;
        const innerDiv = document.createElement("div");
        innerDiv.appendChild(iframe);
        outerDiv.appendChild(innerDiv);
        document.body.appendChild(outerDiv);
        // write srcdoc explicitly to document for Safari
        const srcdoc = iframe.srcdoc;
        iframe.srcdoc = "";
        iframe.contentDocument!.write(srcdoc);

        return new Promise<void>((resolve) => {
            outerDiv.onclick = (event) => {
                if (event.target === outerDiv && !options.persistent) {
                    outerDiv.remove();
                    style.remove();
                    resolve();
                }
            };
            iframe.contentWindow!.addEventListener("message", (event) => {
                if (event.data === "close") {
                    outerDiv.remove();
                    style.remove();
                    resolve();
                } else if (event.data === "resize" && options.autoHeight) {
                    innerDiv.style.height = iframe.contentWindow!.document.body.scrollHeight + "px";
                }
            });
        });
    };
}

export function showButton(component: any, modals: [(options: WidgetModalOptions) => Promise<void>]) {
    return (options: WidgetButtonOptions = {}) => {
        const lang = (options.lang || navigator.language).startsWith("de") ? "de" : "en";

        Vue.use(VueI18n);
        const i18n = new VueI18n({
            messages: { de, en },
            locale: lang,
        });

        const button = new Vue({
            i18n,
            render: (h) =>
                h(component, {
                    props: { ...options.params, ...options.buttonParams },
                    on: { click: (i: number) => modals[i](options) },
                }),
        });

        const id = randomId();
        const style = document.createElement("style");
        style.textContent = `
#${id} {
    position: fixed;
    z-index: 2147483647;
    ${options.position === "topLeft" || options.position === "topRight" ? "top" : "bottom"}: ${toSize(
            options.offsetY,
            "10px"
        )};
    ${options.position === "bottomRight" || options.position === "topRight" ? "right" : "left"}: ${toSize(
            options.offsetX,
            "10px"
        )};
}`;
        document.body.appendChild(style);

        const outerDiv = document.createElement("div");
        outerDiv.id = id;
        const innerDiv = document.createElement("div");
        outerDiv.appendChild(innerDiv);
        document.body.appendChild(outerDiv);
        button.$mount(innerDiv);
        i18n.mergeLocaleMessage("de", options.labels?.de || {});
        i18n.mergeLocaleMessage("en", options.labels?.en || {});
        return outerDiv;
    };
}
