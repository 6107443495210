import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export interface Videochat {
    readonly id: string;
    readonly dealerId: string;
    readonly created: Date;
    readonly creatorUserId: string | null;
    readonly userIds: string[];
    readonly joinEvents: VideochatJoinEvent[];
}

export interface VideochatAccessToken {
    readonly appId: string;
    readonly jwt: string;
}

export interface VideochatJoinEvent {
    readonly created: Date;
    readonly name: string | null;
}

export interface VideochatSearchResults {
    readonly results: Videochat[];
    readonly totalSize: number;
    readonly searchId: number;
}

interface VideochatApi {
    addJoinEvent(id: string, name: string): Promise<void>;
    acceptInvitation(id: string): Promise<void>;
    dismissInvitation(id: string): Promise<void>;
    generateAccessToken(id: string): Promise<VideochatAccessToken>;
    generateQrCodeImageLink(id: string, displayName: string): string;
    generateStartExternalLink(groupId: string, displayName: string): string;
    getById(id: string): Promise<Videochat>;
    getMyVideochats(start: number, size: number, searchId: number): Promise<VideochatSearchResults>;
    invite(id: string, inviteeUserId: string): Promise<void>;
    isConfigured(): Promise<boolean>;
    start(): Promise<string>;
}

export const videochatApi: VideochatApi = {
    async addJoinEvent(id, name) {
        await axios.post(`/api/videochats/${id}/_add-join-event`, null, {
            params: {
                name,
            },
        });
    },

    async acceptInvitation(id) {
        await axios.post(`/api/videochats/${id}/_accept-invitation`);
    },

    async dismissInvitation(id) {
        await axios.post(`/api/videochats/${id}/_dismiss-invitation`);
    },

    async generateAccessToken(id) {
        return (await axios.get(`/api/videochats/${id}/_generate-access-token`)).data;
    },

    generateQrCodeImageLink(id, displayName) {
        return `/api/videochats/${id}/qr-code.png?displayName=${encodeURIComponent(displayName)}`;
    },

    generateStartExternalLink(groupId, displayName) {
        return `${axios.defaults.baseURL || ""}/api/videochats/_external?groupId=${encodeURIComponent(
            groupId
        )}&displayName=${encodeURIComponent(displayName)}`;
    },

    async getById(id) {
        return cloneObject((await axios.get(`/api/videochats/${id}`)).data);
    },

    async getMyVideochats(start, size, searchId) {
        return cloneObject(
            (
                await axios.get("/api/videochats", {
                    params: {
                        start,
                        size,
                        searchId,
                    },
                })
            ).data
        );
    },

    async invite(id, inviteeUserId) {
        await axios.post(`/api/videochats/${id}/_invite`, null, {
            params: {
                inviteeUserId,
            },
        });
    },

    async isConfigured() {
        return (await axios.get("/api/videochats/is-configured")).data;
    },

    async start() {
        return (await axios.post("/api/videochats")).data;
    },
};
