import axios from "axios";

export interface VideochatGroup {
    readonly id: string;
    readonly dealerId: string;
    readonly name: string;
    readonly preferForIntenvoryVehicles: boolean;
    readonly userIds: string[];
}

export interface VideochatGroupForm {
    readonly name: string;
    readonly preferForIntenvoryVehicles: boolean;
    readonly userIds: string[];
}

export interface VideochatGroupOverview {
    readonly id: string;
    readonly name: string;
    readonly numberOfAvailableUsers: number;
}

export interface ContactInfo {
    readonly name: string;
    readonly number: string;
    readonly emailAddress: string | null;
    readonly note: string;
}

interface VideochatGroupApi {
    add(dealerId: string, form: VideochatGroupForm): Promise<void>;
    getByDealer(dealerId: string): Promise<VideochatGroup[]>;
    edit(id: string, videochatGroupForm: VideochatGroupForm): Promise<void>;
    delete(id: string): Promise<void>;
    getOverview(dealerId?: string, groupId?: string, internalIdOrVin?: string): Promise<VideochatGroupOverview[]>;
    createCase(id: string, contactInfo: ContactInfo): Promise<void>;
}

export const videochatGroupApi: VideochatGroupApi = {
    async add(dealerId, form) {
        await axios.post(`/api/videochat-groups/${dealerId}`, form);
    },

    async getByDealer(dealerId) {
        return (await axios.get(`/api/videochat-groups/${dealerId}`)).data;
    },

    async edit(id, form) {
        await axios.put(`/api/videochat-groups/_/${id}`, form);
    },

    async delete(id) {
        await axios.delete(`/api/videochat-groups/_/${id}`);
    },

    async getOverview(dealerId, groupId, internalIdOrVin) {
        return (
            await axios.get("/api/videochat-groups", {
                params: {
                    dealerId,
                    groupId,
                    internalIdOrVin,
                },
            })
        ).data;
    },

    async createCase(id, contactInfo) {
        await axios.post(`/api/videochat-groups/_/${id}/_create-case`, contactInfo);
    },
};
