export default {
    common: {
        dataProtectionBefore: "Ich habe die",
        dataProtection: "Datenschutzerklärung",
        dataProtectionAfter: "zur Kenntnis genommen",
        noDataProtection: "Bestätigen Sie, dass Sie die Datenschutzerklärung zur Kenntnis genommen haben.",
    },
    videochat: {
        availableEmployees: "Verfügbare Mitarbeiter",
        noEmployees: "Es sind zur Zeit keine Mitarbeiter für einen Videochat verfügbar.",
        name: "Ihr Name",
        noName: "Geben Sie Ihren Namen an.",
        start: "Videochat starten",
        title: "Fragen?",
        explanation: "Wir beraten Sie gerne persönlich.",
        buttonText: "Jetzt Videochat starten",
    },
    whatsapp: {
        defaultText: "Hallo! Ich hätte gerne weitere Informationen zu folgendem Angebot:",
        title: "WhatsApp Sofortkontakt",
        buttonText: "Jetzt Nachricht senden",
    },
};
