
import { videochatGroupApi, VideochatGroupOverview } from "@/api/videochatGroups";
import { videochatApi } from "@/api/videochats";
import Vue from "vue";

export default Vue.extend({
    props: {
        dealerId: {
            type: String,
            required: false,
        },
        groupId: {
            type: String,
            required: false,
        },
        internalIdOrVin: {
            type: String,
            required: false,
        },
        dataProtectionLink: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            groups: [] as VideochatGroupOverview[],
            selectedGroup: null as number | null,
            name: "",
            loading: true,
        };
    },

    watch: {
        selectedGroup() {
            postMessage("resize", "*");
        },
    },

    methods: {
        submit() {
            if (!(this.$refs.form as any).validate()) {
                return;
            }

            parent.open(videochatApi.generateStartExternalLink(this.groups[this.selectedGroup!].id, this.name.trim()));
            this.close();
        },

        close() {
            postMessage("close", "*");
        },
    },

    async mounted() {
        if (await videochatApi.isConfigured()) {
            this.groups = (await videochatGroupApi.getOverview(this.dealerId, this.groupId, this.internalIdOrVin))
                .filter((g) => g.numberOfAvailableUsers > 0)
                .sort((a, b) => a.name.localeCompare(b.name, this.$i18n.locale));

            if (this.groups.length === 1) {
                this.selectedGroup = 0;
            }
        }

        this.loading = false;
        await this.$nextTick();
        postMessage("resize", "*");
    },
});
