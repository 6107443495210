
import { whatsAppAccountApi } from "@/api/whatsAppAccounts";
import { parseAndFormatNumber } from "@/util/phoneNumberUtils";
import Vue from "vue";

interface WhatsAppNumber {
    readonly number: string;
    readonly label?: string | null;
}

export default Vue.extend({
    props: {
        number: {
            type: String,
            default: "",
        },
        numbers: {
            type: Array as () => WhatsAppNumber[],
            default() {
                return [];
            },
        },
        text: {
            type: String,
            default: "",
        },
        internalIdOrVin: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            availableNumbers: [] as WhatsAppNumber[],
        };
    },

    methods: {
        openChat(n: string, alwaysClose = true) {
            if (n.startsWith("+")) {
                n = n.substring(1);
            }

            let text = this.text || (this.$t("whatsapp.defaultText") as string);
            if (this.internalIdOrVin) {
                text += ` [${this.internalIdOrVin}]`;
            }
            text += ` ${parent.location.href}`;

            const url = `https://wa.me/${n}?text=${encodeURIComponent(text.trim())}`;
            let opened = false;
            try {
                opened = !!parent.open(url);
                return opened;
            } finally {
                if (opened || alwaysClose) {
                    this.close();
                }
            }
        },

        formatNumber(n: string) {
            if (!n.startsWith("+")) {
                n = `+${n}`;
            }
            return parseAndFormatNumber(n, "INTERNATIONAL") || n;
        },

        close() {
            postMessage("close", "*");
        },
    },

    async mounted() {
        let numbers: WhatsAppNumber[] = (await whatsAppAccountApi.getAllAccounts(this.internalIdOrVin))
            .filter((a) => a.label)
            .sort((a, b) => a.label!.localeCompare(b.label!, this.$i18n.locale));
        if (this.numbers.length) {
            numbers = this.numbers;
        } else if (this.number) {
            numbers = [{ number: this.number }];
        }

        if (numbers.length === 1) {
            if (this.openChat(numbers[0].number, false)) {
                return;
            }
        }

        this.availableNumbers = numbers;
        await this.$nextTick();
        postMessage("resize", "*");
    },
});
