import axios from "axios";

export enum TypeHint {
    CASE = "CASE",
    OPPORTUNITY = "OPPORTUNITY",
    AUTO_DETECT = "AUTO_DETECT",
}

export enum WhatsAppApiType {
    CLOUD_API = "CLOUD_API",
    ON_PREMISES_API = "ON_PREMISES_API",
}

export interface WhatsAppAccountCreationForm {
    readonly name: string;
    readonly channelId: string | null;
    readonly apiType: WhatsAppApiType;
    readonly apiKey: string;
    readonly label: string | null;
    readonly type: TypeHint;
    readonly overrideDealerIdByVehicleLocation: boolean;
    readonly individualReceivers: string[];
    readonly officeHoursId: string | null;
    readonly insideOfficeHoursMessage: string | null;
    readonly outsideOfficeHoursMessage: string | null;
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly sourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

export interface WhatsAppAccountUpdateForm {
    readonly name: string;
    readonly apiType: WhatsAppApiType;
    readonly label: string | null;
    readonly type: TypeHint;
    readonly overrideDealerIdByVehicleLocation: boolean;
    readonly individualReceivers: string[];
    readonly officeHoursId: string | null;
    readonly insideOfficeHoursMessage: string | null;
    readonly outsideOfficeHoursMessage: string | null;
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly sourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

export interface WhatsAppAccount {
    readonly id: string;
    readonly name: string;
    readonly apiType: WhatsAppApiType;
    readonly dealerId: string;
    readonly number: string;
    readonly label: string | null;
    readonly type: TypeHint;
    readonly overrideDealerIdByVehicleLocation: boolean;
    readonly individualReceivers: string[];
    readonly officeHoursId: string | null;
    readonly insideOfficeHoursMessage: string | null;
    readonly outsideOfficeHoursMessage: string | null;
    readonly caseType: string | null;
    readonly escalationGroupReceivers: string[];
    readonly sourceId: string | null;
    readonly opportunityTeamReceivers: string[];
}

export interface WhatsAppTemplate {
    readonly namespace: string;
    readonly name: string;
    readonly language: string;
    readonly text: string;
}

interface WhatsAppAccountApi {
    getAllAccounts(internalIdOrVin?: string): Promise<WhatsAppAccount[]>;
    getAccounts(dealerId: string): Promise<WhatsAppAccount[]>;
    addAccount(dealerId: string, form: WhatsAppAccountCreationForm): Promise<void>;
    editAccount(id: string, form: WhatsAppAccountUpdateForm): Promise<void>;
    deleteAccount(id: string): Promise<void>;
    getTemplates(id: string): Promise<WhatsAppTemplate[]>;
}

export const whatsAppAccountApi: WhatsAppAccountApi = {
    async getAllAccounts(internalIdOrVin) {
        return (await axios.get("/api/whatsapp-accounts", { params: { internalIdOrVin } })).data;
    },

    async getAccounts(dealerId) {
        return (await axios.get(`/api/whatsapp-accounts/${dealerId}`)).data;
    },

    async addAccount(dealerId, form) {
        await axios.post(`/api/whatsapp-accounts/${dealerId}`, form);
    },

    async editAccount(id, form) {
        await axios.put(`/api/whatsapp-accounts/_/${id}`, form);
    },

    async deleteAccount(id) {
        await axios.delete(`/api/whatsapp-accounts/_/${id}`);
    },

    async getTemplates(id) {
        return (await axios.get(`/api/whatsapp-accounts/_/${id}/templates`)).data;
    },
};
