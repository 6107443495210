import { AsYouType, CountryCode, parsePhoneNumberFromString } from "libphonenumber-js/max";

export type PhoneNumberFormat = "NATIONAL" | "INTERNATIONAL" | "E.164";
export type PhoneNumberCountryCode = CountryCode;

export function parseAndFormatNumber(
    text: string,
    outputNumberFormat: PhoneNumberFormat,
    defaultCountryCode?: PhoneNumberCountryCode
) {
    const parsedNumber = parsePhoneNumberFromString(text, defaultCountryCode);
    if (!parsedNumber || !parsedNumber.isPossible()) {
        return null;
    }

    if (parsedNumber.isNonGeographic() && outputNumberFormat === "NATIONAL") {
        return null;
    }

    return parsedNumber.format(outputNumberFormat);
}

export function isMobile(e164: string) {
    return parsePhoneNumberFromString(e164)?.getType() === "MOBILE";
}

export function guessCountryCode(text: string, defaultCountryCode: PhoneNumberCountryCode) {
    const ayt = new AsYouType();
    ayt.input(text.trim());
    return ayt.getCountry() || defaultCountryCode;
}

export function isValidNumber(text: string) {
    return !!text.match(/^\+[1-9]\d{1,30}$/);
}

export function isRealNumber(e164: string) {
    if (!isValidNumber(e164)) {
        return false;
    }

    const parsedNumber = parsePhoneNumberFromString(e164);
    return !!parsedNumber && (parsedNumber.isValid() || (parsedNumber.isNonGeographic() && parsedNumber.isPossible()));
}
